import axios from "axios";
import Cookie from "js-cookie";
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_SAVE_SHIPPING, CART_SAVE_PAYMENT } from "../constants/cartConstants";

import { API_URL } from "../constants/apiURL";

const addToCart = (productId, qty) => async (dispatch, getState) => {
    //getState is made available by redux-thunk  middleware
    try {
        const { data } = await axios.get( API_URL + '/api/products/' + productId);

        dispatch({ type: CART_ADD_ITEM, 
                   payload: {
                   product: data._id,
                   name: data.name,
                   image: data.image,
                   price: data.price,
                   countInStock: data.countInStock,
                   qty }
                });
        
        const { cart: { cartItems } } = getState();
        //provides access to cart items after dispatching the CART_ADD_ITEM action to store.js
        Cookie.set("cartItems", JSON.stringify(cartItems));
        //saves cart items in a cookie
    }
    catch (error) {

    }
}

const removeFromCart = (productId) => (dispatch, getState) => {

    dispatch({ type: CART_REMOVE_ITEM, payload: productId });
  
    const { cart: { cartItems } } = getState();
    Cookie.set("cartItems", JSON.stringify(cartItems));
}

const saveShipping = (data) => (dispatch) => {

    dispatch({ type: CART_SAVE_SHIPPING, payload: data });

}

const savePayment = (data) => (dispatch) => {
    dispatch({ type: CART_SAVE_PAYMENT, payload: data });
}

export { addToCart, removeFromCart, saveShipping, savePayment }