import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { savePayment } from '../actions/cartActions';
import CheckoutSteps from '../components/CheckoutSteps';
/*it convention to use lowercase for action names */

function PaymentScreen(props) {

    const [paymentMethod, setPaymentMethod] = useState('');

    const dispatch = useDispatch();
    
    const submitHandler = (e) => {
      e.preventDefault();
         //prevents screen from being refreshed when user clicks on submit button
      dispatch(savePayment({ paymentMethod })); //see cartActions.js for definition of Register
      props.history.push('placeorder'); //redirect to /placeorder screen
    }

    return  <div>
                <CheckoutSteps step1 step2 step3 ></CheckoutSteps>
                <div className="form">
                    <form onSubmit={submitHandler} >
                    {/* <form > */}
                        <ul className="form-container">
                            <li>
                                <h2>Payment</h2>
                            </li>
                            <li>
                                <div>
                                    <input
                                        type="radio"
                                        name="paymentMethod"
                                        id="paymentMethod"
                                        value="paypal"
                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                    ></input>
                                    <label for="paymentMethod">Paypal</label>
                                </div>
                            </li>

                            <li>
                                <button type="submit" className="button primary">Continue</button>
                            </li>

                        </ul>
                    </form>
                </div>
            </div>

}
  
export default PaymentScreen;