import axios from "axios";
import { ORDER_CREATE_REQUEST, ORDER_CREATE_SUCCESS, ORDER_CREATE_FAIL, 
         ORDER_DETAILS_REQUEST, ORDER_DETAILS_SUCCESS, ORDER_DETAILS_FAIL, 
         ORDER_PAY_REQUEST, ORDER_PAY_SUCCESS, ORDER_PAY_FAIL, 
         MY_ORDER_LIST_REQUEST, MY_ORDER_LIST_SUCCESS, MY_ORDER_LIST_FAIL,
         ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS, ORDER_LIST_FAIL, 
         ORDER_DELETE_REQUEST, ORDER_DELETE_SUCCESS, ORDER_DELETE_FAIL 
        } from "../constants/orderConstants";

import { API_URL } from "../constants/apiURL";

const createOrder = (order) => async (dispatch, getState) => {
    try {

      dispatch({ type: ORDER_CREATE_REQUEST, payload: order });

      const { userSignin: { userInfo } } = getState();

      const { data: { data: newOrder } } = await axios.post( API_URL + '/api/orders', order, {
                                                 headers: {
                                                            Authorization: ' Bearer ' + userInfo.token
                                                          }
                                            });    
      dispatch({ type: ORDER_CREATE_SUCCESS, payload: newOrder });

    } 
    catch (error) {

      dispatch({ type: ORDER_CREATE_FAIL, payload: error.message });

    }
}

const detailsOrder = (orderId) => async (dispatch, getState) => {
    try {

      dispatch({ type: ORDER_DETAILS_REQUEST, payload: orderId });

      const { userSignin: { userInfo } } = getState();

      const { data } = await axios.get( API_URL + '/api/orders/' + orderId, {
                                        headers:
                                            { Authorization: 'Bearer ' + userInfo.token }
                                        });

      dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });

    } 
    catch (error) {

      dispatch({ type: ORDER_DETAILS_FAIL, payload: error.message });

    }
}

const payOrder = (order, paymentResult) => async (dispatch, getState) => {
  try {

    dispatch({ type: ORDER_PAY_REQUEST, payload: paymentResult });

    const { userSignin: { userInfo } } = getState();

    const { data } = await axios.put( API_URL + '/api/orders/' + order._id + '/pay', paymentResult, {
                                                  headers:
                                                    { Authorization: 'Bearer ' + userInfo.token }
                                                });

    dispatch({ type: ORDER_PAY_SUCCESS, payload: data });

  } 
  catch (error) {

    dispatch({ type: ORDER_PAY_FAIL, payload: error.message });

  }
}

// const listMyOrders = () => async (dispatch, getState) => {
//   try {

//     // dispatch({ type: MY_ORDER_LIST_REQUEST });
//     

//     const { userSignin: { userInfo } } = getState();

//     const { data } = await axios.get("/api/orders/mine", {
//                                                             headers:
//                                                               { Authorization: 'Bearer ' + userInfo.token }
//                                                           });
                                     
//     dispatch({ type: MY_ORDER_LIST_SUCCESS, payload: data });

//   } 
//   catch (error) {

//     dispatch({ type: MY_ORDER_LIST_FAIL, payload: error.message });

//   }
// }
const listMyOrders = ( { userId } ) => async (dispatch, getState) => {
  try {

    // dispatch({ type: MY_ORDER_LIST_REQUEST });
    dispatch({ type: MY_ORDER_LIST_REQUEST, payload: { userId } } );

    const { userSignin: { userInfo } } = getState();

    const { data } = await axios.get( API_URL + '/api/orders/mine/' + userId, {
                                                            headers:
                                                              { Authorization: 'Bearer ' + userInfo.token }
                                                          });
                                     
    dispatch({ type: MY_ORDER_LIST_SUCCESS, payload: data });

  } 
  catch (error) {

    dispatch({ type: MY_ORDER_LIST_FAIL, payload: error.message });

  }
}

const listOrders = () => async (dispatch, getState) => {

  try {

    dispatch({ type: ORDER_LIST_REQUEST});

    const { userSignin: { userInfo } } = getState();

    const { data } = await axios.get( API_URL + '/api/orders', {
                                      headers:
                                        { Authorization: 'Bearer ' + userInfo.token }
                                    });

    dispatch({ type: ORDER_LIST_SUCCESS, payload: data });

  } 
  catch (error) {

    dispatch({ type: ORDER_LIST_FAIL, payload: error.message });

  }

}

const deleteOrder = (orderId) => async (dispatch, getState) => {
  
  try {

    dispatch({ type: ORDER_DELETE_REQUEST, payload: orderId });

    const { userSignin: { userInfo } } = getState();

    const { data } = await axios.delete( API_URL + '/api/orders/' + orderId, {
                                          headers:
                                            { Authorization: 'Bearer ' + userInfo.token }
                                        });

    dispatch({ type: ORDER_DELETE_SUCCESS, payload: data })
  } 
  catch (error) {

    dispatch({ type: ORDER_DELETE_FAIL, payload: error.message });

  }
}

export { createOrder, 
         detailsOrder, 
         payOrder, 
         listMyOrders,
         listOrders,
         deleteOrder
       };